import { render, staticRenderFns } from "./LocationSlider.vue?vue&type=template&id=bc912190"
import script from "./LocationSlider.vue?vue&type=script&lang=js"
export * from "./LocationSlider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardsLocationCard: require('/usr/src/app/components/cards/LocationCard.vue').default,SlidersSliderDefault: require('/usr/src/app/components/sliders/SliderDefault.vue').default})
